exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-board-9-cn-bak-js": () => import("./../../../src/pages/board9.cn.bak.js" /* webpackChunkName: "component---src-pages-board-9-cn-bak-js" */),
  "component---src-pages-board-9-cn-js": () => import("./../../../src/pages/board9.cn.js" /* webpackChunkName: "component---src-pages-board-9-cn-js" */),
  "component---src-pages-bp-aug-js": () => import("./../../../src/pages/bp.aug.js" /* webpackChunkName: "component---src-pages-bp-aug-js" */),
  "component---src-pages-bp-cgc-js": () => import("./../../../src/pages/bp.cgc.js" /* webpackChunkName: "component---src-pages-bp-cgc-js" */),
  "component---src-pages-bp-jason-js": () => import("./../../../src/pages/bp.jason.js" /* webpackChunkName: "component---src-pages-bp-jason-js" */),
  "component---src-pages-bp-k-2-vc-js": () => import("./../../../src/pages/bp.k2vc.js" /* webpackChunkName: "component---src-pages-bp-k-2-vc-js" */),
  "component---src-pages-bp-redpoint-js": () => import("./../../../src/pages/bp.redpoint.js" /* webpackChunkName: "component---src-pages-bp-redpoint-js" */),
  "component---src-pages-bp-story-bak-js": () => import("./../../../src/pages/bp.story.bak.js" /* webpackChunkName: "component---src-pages-bp-story-bak-js" */),
  "component---src-pages-bp-story-js": () => import("./../../../src/pages/bp.story.js" /* webpackChunkName: "component---src-pages-bp-story-js" */),
  "component---src-pages-index-bak-js": () => import("./../../../src/pages/index.bak.js" /* webpackChunkName: "component---src-pages-index-bak-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */)
}

